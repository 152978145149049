import React from 'react'
import { Link } from 'react-router-dom';
export default function Error404() {
  return (
    <div>
        <h1 className='text-center my-4'>ERROR 404</h1>
        <h1 className='text-center'>SOME THING WENT WRONG</h1>
        <h3 className='text-center'><Link to={'/'}>Back To Home</Link> </h3>
    </div>
  )
}
