import icon from "../star.png";
export let homecard = [
  {
    id: 1,
    icon: icon,
    heading: "Leadership Training",
    Image: "https://ohrrs.com/wp-content/uploads/2023/10/feature-1-1.jpg",
  },
  {
    id: 2,
    icon: icon,
    heading: "Improving Resource",
    Image: "https://ohrrs.com/wp-content/uploads/2023/10/service-3-1-1.jpg",
  },
  {
    id: 3,
    icon: icon,
    heading: "Recruitement",
    Image: "https://ohrrs.com/wp-content/uploads/2023/10/feature-3-1.jpg",
  },
];
