import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import Home from './pages/Home';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import About from './pages/About';
import OurSolution from './pages/OurSolution';
import Contact from './pages/Contact';
import Error404 from './pages/error404';
const root = ReactDOM.createRoot(document.getElementById('root'));
let allRoutes=createBrowserRouter(
  [
   {
    path:'/',
    element:<Home/>
   },
   {
    path:'about-us',
    element:<About/>
   },
   {
    path:'our-solution',
    element:<OurSolution/>
   },
   {
    path:'contact-us',
    element:<Contact/>
   },
   {
    path:'*',
    element:<Error404/>
   }
]
);
root.render(
  <React.StrictMode>
    <RouterProvider router={allRoutes}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
