import React from "react";
import Header from "../components/Header";
import {Col, Container, Row} from 'react-bootstrap';
import Footer from "../components/Footer";
import '../styles/contact.css'
import { Link } from "react-router-dom";
export default function Contact() {
  return (
    <>
      <Container fluid className="banner mb-5 pb-4 pb-md-0">
        <Header />
        <div className="line mx-auto bg-white" />
        <h1 className="text-white">CONTACT US</h1>
        <span className="text-white fs-4 ms-5 mx-auto ms-md-5"><Link to={'/'}>HOME</Link> / CONTACT</span>
      </Container>
      <Container className="contact_form my-5">
        <Row>
         <Col className="col-md-6 col-12 text-md-start text-center py-4 px-3 pe-md-5">
         <h2>Write a Message</h2>
         <p>We’re here to assist you. Whether you have questions, require more information about our services, or want to explore potential collaboration opportunities, don’t hesitate to get in touch. Our dedicated team at OHRRS is ready to provide the answers and support you need. Feel free to reach out via phone, email, or the contact form below. We look forward to hearing from you and helping you achieve your goals.</p>
         </Col>
         <Col className="col-md-6 col-12 py-4 ps-5">
         <form className="d-flex flex-column">
          <label htmlFor="name">Your Name</label>
          <input type="text" name="name" id="name"/>
          <label htmlFor="email">Your Email</label>
          <input type="email" name="email" id="email"/>
          <label htmlFor="subject">Subject</label>
          <input type="text" name="subject" id="subject" />
          <input type="button" name="submit" id="submit" value="submit" />
         </form>
         </Col>
        </Row>
      </Container>

      <Footer/>
    </>
  );
}
