export let abouttext=[
    {
        title:'About Us',
        desc:'Oriental HR and Research Services (OHRRS), founded in October 2023, is dedicated to advancing social health research, with a primary focus on large-scale projects like NFHS, DLHS, and AHS. Additionally, we excel in secondary market research, offering a wide range of services including Focus Group Discussions (FGD), Central Location Tests (CLT), Household Surveys, House Listing Mapping, Selective Interviews, Telly Calling Backcheck Facility, and third-party quality audits for various survey endeavors. Our mission is to contribute to the field of research and data collection with precision and reliability'
    },
    {
        title:'Our Mission',
        desc:"OHRRS mission is to deliver top-quality work that aligns with our clients’ satisfaction. We are dedicated to uncovering the genuine ground realities through our experienced and committed workforce. With a focus on punctuality and unwavering dedication, we strive to complete every project within the specified timeframe."


    },
    {
        title:'Our Vision',
        desc:'The vision of OHRRS is to achieve significant milestones in the fields of social research, market research, and opinion polls/exit polls. We are committed to conducting fieldwork in challenging environments, such as the North East States, to gather valuable insights and data.'
    }
]