export let aboutcard=[
    {
     title:'House Keeping',
     img:'https://ohrrs.com/wp-content/uploads/2023/10/Executive-Housekeeping-Services-300x208-2.png',
     desc:'At OHRRS, we take pride in providing top-notch Housekeeping services. Our dedicated team is committed to maintaining the cleanliness, hygiene, and orderliness of your space, whether it’s your home or workplace. We understand the importance of a clean environment for your well-being and productivity. Count on OHRRS for thorough and professional Housekeeping solutions that ensure your space is always in its best condition.'
    },
    {
     title:'Security',
     img:'https://ohrrs.com/wp-content/uploads/2023/10/235-2353122_security-guard-services-security-guard-png-300x212-1.jpg',
     desc:'Security is our priority at OHRRS. We offer comprehensive security solutions to safeguard your assets, property, and peace of mind. Our expert team is trained to provide a range of security services, including personnel security, access control, surveillance, and risk assessment. With OHRRS, you can trust that your safety and protection are in capable hands. Contact us for reliable and customized security solutions tailored to your specific needs.'
    },
    {
     title:'Delivery Person',
     img:'https://ohrrs.com/wp-content/uploads/2023/10/depositphotos_76029301-stock-photo-delivery-man-giving-a-cardboard-300x200-1.webp',
     desc:'OHRRS is your trusted source for reliable Delivery Personnel. We specialize in providing skilled and efficient delivery professionals to meet your business or personal delivery needs. Whether it’s ensuring the timely distribution of goods, documents, or parcels, our dedicated team is here to make deliveries convenient and hassle-free. Count on OHRRS to connect you with dependable Delivery Personnel who prioritize speed, accuracy, and professionalism in every delivery task'
    }
]