import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { homecard } from "../data/homecard";
import Header from "../components/Header";
import "../styles/home.css";
import Footer from "../components/Footer";
export default function Home() {
  return (
    <>
      <Container fluid className="banner-home mb-5">
        <Header />
        <div className="line mx-auto bg-white" />
        <h1 className="text-white">OHRRS</h1>
        <p>
          Oriental HR and Research Services Private Limited: Unleashing
          Potential, Elevating Businesses, and Nurturing Success by Harnessing
          Human Capital and Delivering Actionable Insights for a Brighter
          Tomorrow.
        </p>
      </Container>
      <Container>
        <Row className="d-flex flex-md-wrap justify-content-center mb-5">
          {homecard.map((carddata, index) => {
            return (
              <Col className="col-md-4 feature_item mx-lg-0 mx-3" key={index}>
                <div className="card text-center">
                  <img
                    width={50}
                    className="mx-auto"
                    src={carddata.icon}
                    alt="icon"
                  />
                  <h3 className="my-4">{carddata.heading}</h3>
                  <img className="Image" src={carddata.Image} alt="image" />
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container fluid className="welcome_section">
        <div className="row">
          <div className="col-md-4 left text-center px-0">
            <img src="https://ohrrs.com/wp-content/uploads/2023/10/service-6-1.jpg" />
          </div>
          <div className="col-md-8 right text-md-start text-center">
            <h5 className="text-primary">Welcome to Agency </h5>
            <h1>Premier Talent Acquisition and Staffing Excellence</h1>
            <p>
              Our organization, Oriental HR and Research Services Private
              Limited, is dedicated to being a premier player in the world of
              talent acquisition and staffing solutions. We specialize in
              providing top-quality recruitment services that are tailored to
              meet the unique needs of our clients. With a commitment to
              excellence, we strive to identify, attract, and nurture the best
              talent available in the market.
            </p>
            <p>
              Our experienced team is adept at matching exceptional candidates
              with the right organizations, ensuring a perfect fit that drives
              success. We take pride in our role as a trusted partner in shaping
              the future of businesses through strategic human resource
              management.
            </p>
          </div>
        </div>
      </Container>
      <Container fluid>
      <div className="row">
        <div className="col-12 contact_section">
          <div className="Box">
            <h3>Contact us now</h3>
            <p>E-Mail: contact@ohrrs.com</p>
            <p>telephone :+91 0000000000</p>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eos, beatae.</p>
          </div>
        </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
