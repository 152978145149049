import React from "react";
import Header from "../components/Header";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import { abouttext } from "../data/abouttext";
import { aboutcard } from "../data/aboutcard";
import { Link } from "react-router-dom";
export default function About() {
  return (
    <>
      <Container fluid className="banner mb-5 pb-4 pb-md-0">
        <Header />
        <div className="line mx-auto bg-white" />
        <h1 className="text-white">ABOUT</h1>
        <span className="text-white fs-4 ms-5 mx-auto ms-md-5"><Link to={'/'}>HOME</Link> / ABOUT</span>

      </Container>
      <Container>
        <Row>
          {abouttext.map((v) => {
            return (
              <Col className="col-12">
                <h3>{v.title}</h3>
                <p>{v.desc}</p>
              </Col>
            );
          })}
        </Row>
        <Row className="d-flex align-item-center justify-content-center">
          {aboutcard.map((v)=>{
            return(
              <Col className="col-md-4 text-center p-3">
              <h3>{v.title}</h3>
              <img className="w-90 my-3" src={v.img} alt="" />
              <p className="text-start">{v.desc}</p>
            </Col>
            )
          })}
     
        </Row>
      </Container>
      <Footer />
    </>
  );
}
