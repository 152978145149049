import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../styles/footer.css'
export default function Footer() {
  return (
      <Container fluid className='footer py-4 px-5'>
        <Row className='footer_row'>
          <Col className='col-md-3 col-sm-6 col-12 wiggite_about text-white'>
          <h4 className='pb-4'>About</h4>
          <p>Oriental HR and Research Services <br/>(OHRRS), founded in October 2023, is dedicated to advancing social health<br/> research, with a primary focus on large-scale projects like NFHS, DLHS, and AHS.</p>
          </Col>
          <Col className='col-md-3 col-sm-6 col-12 wiggite_solution text-white'>
          <h4 className='pb-4'>Our Solution</h4>
          <p>HR Consulting</p>
          <p>Technology</p>
          <p>Staffing Solution</p>
          </Col>
          <Col className='col-md-3 col-sm-6 col-12 wiggite_Links text-white'>
          <h4 className='pb-4'>Quick Links</h4>
          <p>Home</p>
          <p>About Us</p>
          <p>Contact Us</p>
          <p>Our Solution</p>
          </Col>
          <Col className='col-md-3 col-sm-6 col-12 wiggite text-white'>
          <h4 className='pb-4'>Contact Us</h4>
          <p>example@gmail.com , www.example.com</p>
          <p>(+91) 00000000000</p>
          <p>ORIENTAL HR AND RESEARCH <br/>SERVICES PRIVATE LIMITED. G8,<br/> MILAP NAGAR, UTTAM NAGAR, NEW<br/> DELHI - 110059</p>
          </Col>
        </Row>
        <p className='text-center text-white pt-4'>Copyright © 2024. All Rights Reserved.</p>
      </Container>
  )
}
