
import { Container } from "react-bootstrap";
import "../styles/nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import lg from "../hills logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";
export default function Basicnavbar() {
  let [showMenu,setShowMenu] =useState(false)
  return (
    <>
      <Container fluid className={`navbar text-white ${showMenu?'showmenu':''}`}>
        <div className="nav" onClick={()=>setShowMenu(showMenu=false)}>
          <div className="logo">
            <Link to={"/"}>
              <img width={150} src={lg} />
            </Link>
          </div>
          <ul className="menus mb-0 p-0">
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/our-solution"}>Our Solution</Link>
            </li>
            <li>
              <Link to={"/contact-us"}>Contact Us</Link>
            </li>
          </ul>
          <ul className="social_icon mb-0 p-0">
            <li className="navlinkicon">
              <FontAwesomeIcon icon={faTwitter} />
            </li>
            <li className="navlinkicon">
              <FontAwesomeIcon icon={faFacebook} />
            </li>
            <li className="navlinkicon">
              <FontAwesomeIcon icon={faInstagram} />
            </li>
          </ul>
        </div>
        <span onClick={()=>setShowMenu(!showMenu)} className="text-white bars d-lg-none">&#9776;</span>
      </Container>
    </>
  );
}
